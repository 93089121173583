
import { Component, Vue } from '~/utility/pu-class-decorator'
import PopularSearches from '~/components/homepage/PopularSearches.vue'
import type { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { getCampsiteCategoriesPresenter } from '~~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'

interface CategoryInfo {
  name: string
  to: { facet?: string; category: string }
  iconUrl?: string
}

@Component({
  components: {
    PopularSearches,
  },
})
export default class MegaMenu extends Vue {
  private getCategorySliderData(category: CampsiteCategory) {
    return {
      name: category.pretty_name,
      to: { category: category.rootSlug },
      iconUrl: require(`@/assets/images/category-icons/${category.slug}.svg`),
    }
  }

  private get glampingCategory(): CategoryInfo {
    return {
      name: this.$t('Glamping') as string,
      to: { category: 'glamping' },
      iconUrl: require('@/assets/images/category-icons/glamping.svg'),
    }
  }

  get accomodationCategoriesData(): CategoryInfo[] {
    const categoriesPresenter = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    )
    const categories = categoriesPresenter.bySlugMap
    const categoryData: CategoryInfo[] = []
    for (const [index, [_, category]] of Object.entries(categories).entries()) {
      // glamping is not a proper category so need to push it based on the required index
      if (index === 4) {
        categoryData.push(this.glampingCategory)
      }
      if (category.id.toString() != '13') {
        categoryData.push(this.getCategorySliderData(category))
      }
    }
    return categoryData
  }

  get shouldShowArticles() {
    return this.$i18n.locale.startsWith('en-')
  }
}
