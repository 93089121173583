import { render, staticRenderFns } from "./AppMenu.vue?vue&type=template&id=72e382c0&scoped=true"
import script from "./AppMenu.vue?vue&type=script&lang=ts"
export * from "./AppMenu.vue?vue&type=script&lang=ts"
import style0 from "./AppMenu.vue?vue&type=style&index=0&id=72e382c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e382c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuHomepageLink: require('/app/apps/pu-links/link-homepage/PuHomepageLink.vue').default,PopularDestinations: require('/app/components/layouts/components/app-menu/PopularDestinations.vue').default,AppMenuCategories: require('/app/components/layouts/components/app-menu/AppMenuCategories.vue').default,PopularSearches: require('/app/components/homepage/PopularSearches.vue').default,NationalParks: require('/app/apps/national-parks/NationalParks.vue').default,UiCollapse: require('/app/components/ui/UiCollapse.vue').default,ArticleList: require('/app/components/homepage/ArticleList.vue').default,LoadingSpinner: require('/app/components/ui/LoadingSpinner.vue').default,AppMenuLocations: require('/app/components/layouts/components/app-menu/locations/AppMenuLocations.vue').default,AppMenuAboutUs: require('/app/components/layouts/components/app-menu/AppMenuAboutUs.vue').default,AppMenuInternationalization: require('/app/components/layouts/components/app-menu/AppMenuInternationalization.vue').default,AppMenuSocialLinks: require('/app/components/layouts/components/app-menu/AppMenuSocialLinks.vue').default,Version: require('/app/components/layouts/components/Version.vue').default})
