import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=2f970890&scoped=true"
import script from "./MegaMenu.vue?vue&type=script&lang=ts"
export * from "./MegaMenu.vue?vue&type=script&lang=ts"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=2f970890&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f970890",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,AccommodationTypes: require('/app/components/homepage/AccommodationTypes.vue').default,PopularDestinations: require('/app/components/layouts/components/app-menu/PopularDestinations.vue').default,PopularSearches: require('/app/components/homepage/PopularSearches.vue').default,PuSearchLink: require('/app/apps/pu-links/link-search/PuSearchLink.vue').default,ArticleList: require('/app/components/homepage/ArticleList.vue').default,NationalParks: require('/app/apps/national-parks/NationalParks.vue').default})
